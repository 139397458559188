import React from "react";
import Carousel from 'react-bootstrap/Carousel';

function Project_IOS() {
  return (

    <Carousel id="work" >

      <Carousel.Item interval={11500}>
        <div className="video-overlapper"></div>
        <div id="ts_Wrapper" className="carosel-image-video-wrapper d-block w-100">
          <video src='/assets/videos/Website-Ts.mp4' className='carosel-video d-block w-100' webkit-playsinline={true} autoPlay muted playsInline loop>
          </video>
        </div>


        <Carousel.Caption>
          <div className="container">
            <div className="d-flex align-items-center m-b-20">
              <p className="overline head m-b-0">project 01 </p><p className="overline text-opac-60 m-b-0"> /03</p>
            </div>

            <img className="m-b-40 spiral-logo" srcSet="/assets/images/projects/spiral-logo_1x.png 1x, /assets/images/projects/spiral-logo-2x.png 2x, /assets/images/projects/spiral-logo-2x.png 3x" />

            <div className="row">
              <div className="col-lg-4 col-sm-8">
                <p className="sm text-opac-70 m-b-25">The Spiral is the brainchild of the entire Rundown Studio team at Revox. The game was brought to life by the team’s passion and creativity. With a strong from and award-winning Sri Lankan author and the talent and experience of everyone at the team, the project was set in motion at the start of 2022.</p>
              </div>
            </div>
          </div>
        </Carousel.Caption>

      </Carousel.Item>


      <Carousel.Item interval={11000}>
        <div className="video-overlapper"></div>
        <div id="sbh_Wrapper" className="carosel-image-video-wrapper d-block w-100">

          <video src='/assets/videos/Website-Sbh.mp4' className='carosel-video d-block w-100' webkit-playsinline={true} autoPlay muted playsInline loop>
          </video>

        </div>


        <Carousel.Caption>
          <div className="container">
            <div className="d-flex align-items-center m-b-20">
              <p className="overline head m-b-0">project 02 </p><p className="overline text-opac-60 m-b-0"> /03</p>
            </div>
            <img className="m-b-40" srcSet="/assets/images/projects/deadly-alliance_1x.png 1x, /assets/images/projects/deadly-alliance_2x.png 2x, /assets/images/projects/deadly-alliance_2x.png 3x" />

            <div className="row">
              <div className="col-lg-4 col-sm-8">
                <p className="sm text-opac-70 m-b-25">Opposing groups of fugitives and bounty hunters work to either defend their base or raid their enemies’ strong hold. This game hyper focuses on strategy and player teamwork</p>
              </div>
            </div>
          </div>
        </Carousel.Caption>

      </Carousel.Item >




      <Carousel.Item interval={11000}>
        <div className="video-overlapper"></div>
        <div id="starwalker_Wrapper" className="carosel-image-video-wrapper d-block w-100">
          <video src='/assets/videos/Website-Starwalker.mp4' className='carosel-video d-block w-100' webkit-playsinline={true} autoPlay muted playsInline loop>
          </video>

        </div>

        <Carousel.Caption>
          <div className="container">
            <div className="d-flex align-items-center m-b-20">
              <p className="overline head m-b-0">project 03 </p><p className="overline text-opac-60 m-b-0"> /03</p>
            </div>
            {/* <img className="m-b-40" srcSet="/assets/images/projects/Starwalker-Logo.png 1x, /assets/images/projects/Starwalker-Logo@2x.png 2x, /assets/images/projects/Starwalker-Logo@2x.png 3x" /> */}
            <h2>World Creation Project</h2>


            <div className="row">
              <div className="col-lg-4 col-sm-8">
                <p className="sm text-opac-70 m-b-25">
                  {/* This setting is set inside an O’Neill Cylinder and as such gives prominence to the beautiful landscape of a cylindrical world. The game focuses on surviving the harsh conditions within. <br></br> */}
                  The team was tasked with creating breathtaking visuals for this amazing setting. From creating stunning landscapes, immersive lighting to the small insects you see dashing across your screen. While gameplay was not ours to decide the task of creating a visual masterpiece was an amazing undertaking for the team
                </p>
              </div>
            </div>
          </div>
        </Carousel.Caption>
      </Carousel.Item>


    </Carousel>

  );
}

export default Project_IOS;