import React from "react";
import { Link } from "react-router-dom";

function Story_IOS() {

    return (
        <div className="our-story">
            {/* <img className="cobweb2 halloween"  src="/assets/images/Holloween/fun/cobweb2.png" alt="" />
            <img className="wreath christmas"  src="/assets/images/Christmas/fun/wreath.png" alt="" /> */}
            <img className="drummers avurudu" src="/assets/images/avurudu/drum_players.png" alt="" />

            <div className="our-story-section1 ">
                <div className="background-body-overlay bbo-our-story-section1-left"></div>
                <div className="background-body-overlay right bbo-our-story-section1-right"></div>
                <div className="container">
                    <div className="row view-height">
                        <div className="our-story-inner col-lg-7 col-sm-12 text-white d-flex flex-column justify-content-center">
                            <p className="overline head m-b-10 text-opac-80">OUR STORY</p>
                            <h2 className="text-opac-90 m-b-25">We don’t believe in joining the trend, we believe in making one</h2>
                            <p className="our-story-text text-opac-70 m-b-20">Our firm belief is to make games we enjoy playing. We try not to look at the crowd, instead we look at the individual. What story hasn't been told? What can we do to best tell it? That is our ideology.</p>
                            <div className="our-story-inner-text row m-b-40">
                                <div className="col-md-6">
                                    <p className="sm text-opac-56">We believe in creating concept driven ideas. The first block in the foundation is a thought nugget, which we then nurture to a full-fledged picture with conceptualised mind maps and keys. Then we build a game around it. Our end hope is that we make games that people, even the smallest crowd will thoroughly enjoy.</p>
                                </div>
                                <div className="col-md-6">
                                    <p className="sm text-opac-56">The one thing we try not to think too hard about is the current trend or trends. In the new rollouts, we see a rat race of sorts to create the next hype. A question of what would please the masses? </p>
                                </div>
                            </div>

                            <Link className="d-flex align-items-center text-white" to='#'>
                                Get in touch <i className="material-icons-round m-l-10 m-t-2">chevron_right</i>
                            </Link>

                        </div>
                        <div id="our-story-placeholder-sec-1" className="col-lg-5 col-sm-12 section-2 ">

                            <video src='/assets/videos/Our-Story.mp4' className='ourstory-video' webkit-playsinline={true} autoPlay muted playsInline loop >
                            </video>

                        </div>
                    </div>
                </div>

            </div>
            <div className="our-story-section2 ">
                <div className="background-body-overlay bbo-our-story-section2-left"></div>
                <div className="background-body-overlay right bbo-our-story-section2-right"></div>
                <div className="container">
                    <div className="row view-height">
                        {/* Remove Avurudu Keytag afterwards !IMPORTANT */}
                        <div id="our-story-placeholder-sec-2" className="col-lg-6 col-sm-12 section-1 avurudu">

                            <video src='/assets/videos/OUR-BEGINNING.mp4' className='ourstory-video' webkit-playsinline={true} autoPlay muted playsInline loop>
                            </video>

                        </div>
                        <div className="col-lg-6 col-sm-12 text-white d-flex flex-column justify-content-center our-beginning-wrapper">
                            {/* <img className="value-logo1 halloween m-b-10" style={{ width: "250px" }} src="/assets/images/Holloween/Fun/scaryspider.png" alt="" /> */}
                            <img className="value-logo1 avurudu m-b-10" style={{ width: "250px" }} src="/assets/images/avurudu/oil_lamp.png" alt="" />
                            <p className="overline head m-b-10 text-opac-80">Our Beginning</p>
                            <h2 className="text-opac-90 m-b-25">A spark that ignited a wildfire.</h2>
                            <p className="text-opac-70">Our team was assembled on a whim, in order to create a small game on a short deadline. It was then that we realised the skill and potential we could have as a studio. <br></br>
                                After the completion of our first game, we wasted no time and jumped into working on our own passion project.
                                The team’s chemistry and the sheer amount of zeal that we have, enables us to bounce new ideas and throw ground breaking concepts on the fly.
                                <br></br>
                                <br></br>
                                Even though our beginning has been humble, our future will be anything but.

                            </p>

                        </div>

                    </div>
                </div>

            </div>
        </div>
    );
}

export default Story_IOS;