import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import ReactPlayer from 'react-player'
// import Game from "../game/Game";
import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-image-loading";


function Home() {
    var land_isVideoOneReady = false
    var clickCount = 0;


    function handleVideoReadyLand() {
        if (!land_isVideoOneReady) {
            document.getElementById('landingWrapper').classList.remove('loading');
            land_isVideoOneReady = true;
        }

    }

    function handleOnClick() {
        clickCount++;
        if (clickCount > 30) {
            alert("Version IOS Update v3");
        }
    }


    return (
        <div id="landingWrapper" className="land-page-wrapper loading">
            <div className="landing-page-video-overlapper"></div>


            <div id="landingPlaceholder" className="landing-placeholder">
                <ProgressiveImage
                    preview="/assets/images/Landing-page-placeholder-mini.png"
                    src="/assets/images/Landing-page-placeholder-mini.png"
                    render={(src, style) => <img src={src} />}
                />
            </div>

            {/* <video className='background-video' disablePictureInPicture webkit-playsinline={true} autoPlay muted playsInline loop onCanPlayThrough={handleVideoReadyLand}>
                <source src='/assets/videos/game-run.m4v' type='video/mp4' />
            </video> */}

            <ReactPlayer url='/assets/videos/game-run.m4v' className='background-video' playsInline={true} loop={true} muted={true} controls={false} playing={true} onReady={handleVideoReadyLand} />

            <div className="home-screen">
                <a href="https://revox.io/">
                    <div className="revox-placeholder">
                        <ProgressiveImage
                            preview="/assets/images/logo (1).png"
                            src="/assets/images/logo (1).png"
                            render={(src, style) => <img src={src} />}
                        />
                    </div>

                </a>

                <div className="d-flex align-items-center justify-content-center" style={{ height: "100vh" }}>
                    <div className="d-block text-center text-wrapper landing-page-center-wrapper">
                        {/* <div className="landing-page-fun-backdrop christmas">
                            <ProgressiveImage
                                preview="/assets/images/Christmas/fun/deco_1.png"
                                src="/assets/images/Christmas/fun/deco_1.png"
                                render={(src, style) => <img src={src} />}
                            />

                        </div> */}
                        <div className="landing-page-fun-backdrop avurudu">
                            <ProgressiveImage
                                preview="/assets/images/avurudu/traditional_sun.png"
                                src="/assets/images/avurudu/traditional_sun.png"
                                render={(src, style) => <img src={src} />}
                            />

                        </div>
                        <img className="logo" src="/assets/images/logos/rundownstudios_logo_vertical_white_240.png" alt="" onClick={handleOnClick} />
                        <h1 className="heading">We are<br />Rundown<br />Studios</h1>
                        <p className="hint-text">“To shake the very foundation we stand on,<br /> that is our purpose.”</p>
                        {/* <Button variant="outline" className="home-button exolore coming-soon disabled" >Mobile Version Coming Soon</Button> */}
                        <a href="https://play.rundownstudios.com/">
                            <Button variant="outline-primary" className="home-button exolore" >Enter</Button>
                        </a>
                        <br /><br />
                        <Link to={'/home'}>
                            <Button variant="outline-primary" className="home-button">Tour in 2D</Button>
                        </Link>

                    </div>
                </div>

            </div>
        </div >

    )
}

export default Home;