import React from "react";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";
import { useLocation } from 'react-router';


function NavBar() {

    const location = useLocation();


    function toggleOffNavBar() {
        document.getElementById("navBarMenuCheckBox").checked = false;
    }
    function goToBlog() {
        window.location.href = 'https://www.artstation.com/rundownstudio';
    }
    function goToCareers() {
        window.location.href = 'https://www.revox.io/careers';
    }



    // function goBack() {
    //     document.getElementById("pageContainer").classList.add("playing");
    // }
    return (
        <>

            <div className={`nav-bar ${location.pathname === '/play' ? 'playing' : ''}`}>
                {/* <Link to={'/home'}>
                    <span className="back-button material-icons-round" >arrow_back_ios</span>
                </Link> */}

                <div className="mobile-nav-bar">
                    <nav role="navigation">
                        <div id="menuToggle">

                            <input id="navBarMenuCheckBox" type="checkbox" />

                            <span></span>
                            <span></span>
                            <span></span>

                            <ul id="menu">
                                <li className="m-r-80 ">
                                    <Link to="hero" className="menu-link menu-link-und" activeClass='active' spy={true} smooth={true} offset={50} duration={100} onClick={toggleOffNavBar} >
                                        Who we are
                                    </Link>
                                </li>
                                <li className="m-r-80 ">
                                    <Link to="our-team" className="menu-link menu-link-und" activeClass='active' spy={true} smooth={true} offset={50} duration={100} onClick={toggleOffNavBar} >
                                        People
                                    </Link>
                                </li>
                                <li className="m-r-80 ">
                                    <Link to="our-team" className="menu-link menu-link-und" activeClass='active' spy={true} smooth={true} offset={50} duration={100} onClick={goToBlog} >
                                        ArtStation
                                    </Link>
                                </li>
                                <li className="m-r-80 ">
                                    <Link to="work" className="menu-link menu-link-und" activeClass='active' spy={true} smooth={true} offset={50} duration={100} onClick={toggleOffNavBar} >
                                        Work
                                    </Link>
                                </li>
                                <li className="m-r-80 ">
                                    <Link to="buldWithUS" className="menu-link menu-link-und" activeClass='active' spy={true} smooth={true} offset={50} duration={100} onClick={goToCareers} >
                                        Careers
                                    </Link>
                                </li>
                                <li className="m-r-80 ">
                                    <Link to="buldWithUS" className="menu-link menu-link-und" activeClass='active' spy={true} smooth={true} offset={50} duration={100} onClick={toggleOffNavBar} >
                                        Contact Us
                                    </Link>
                                </li>

                            </ul>
                        </div>
                    </nav>
                </div>


                <div className="desktop-navbar-wrapper desktop-nav-bar  d-flex justify-content-center align-items-center" style={location.pathname !== '/play' ? { display: 'block !important' } : { display: 'none !important' }}>
                    <div id="navBar" className="nav-bar-background"></div>
                    <ul className="ul d-flex">
                        <li className="m-r-80 ">
                            <Link to="hero" className="menu-link menu-link-und" activeClass='active' spy={true} smooth={true} offset={50} duration={100}>
                                Who we are
                            </Link>
                        </li>
                        <li className="m-r-80 ">
                            <Link to="work" className="menu-link menu-link-und" activeClass='active' spy={true} smooth={true} offset={50} duration={100}>
                                Work
                            </Link>
                        </li>
                        <li className="m-r-80 ">
                            <Link to="our-team" className="menu-link menu-link-und" activeClass='active' spy={true} smooth={true} offset={50} duration={100}>
                                People
                            </Link>
                        </li>

                    </ul>

                    <div className="Logo m-r-40">
                        <NavLink to={'/'} className="menu-link">  <img className="m-t-10" src="/assets/images/logos/rundownstudios_logo_badge_white_header.png" alt="" /></NavLink>
                    </div>

                    <ul className="ul d-flex">

                        <li className="m-r-80 ">
                            <Link to="buldWithUS" className="menu-link menu-link-und" activeClass='active' spy={true} smooth={true} offset={50} duration={100}>
                                Contact Us
                            </Link>
                        </li>
                        <li className="m-r-80 ">
                            <Link to="#" className="menu-link menu-link-und" activeClass='active' spy={true} smooth={true} offset={50} duration={100} onClick={goToBlog} >
                                ArtStation
                            </Link>
                        </li>
                        <li className="m-r-80 ">
                            <Link to="#" className="menu-link menu-link-und" activeClass='active' spy={true} smooth={true} offset={50} duration={100} onClick={goToCareers} >
                                Careers
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default NavBar;