import React from "react";
import { useLocation } from 'react-router';


function Footer() {
    const location = useLocation();

    return (
        <div className={`footer ${location.pathname === '/' ? 'hide' : ''}`}>

            {/* <div className="footer"> */}
            <div className="background-body-overlay bbo-footer-left"></div>
            <div className="background-body-overlay right bbo-footer-right"></div>
            <div className="container">
            {/* <img className="halloween-pumpkin christmas" style={{ width: "300px", position: "relative" }} src="/assets/images/Christmas/fun/candy_canes.png" alt="" /> */}
            {/* <img className="halloween-pumpkin avurudu" style={{ width: "150px", position: "relative" }} src="/assets/images/avurudu/mung_kavum.png" alt="" /> */}
                <div className="d-flex justify-content-between">
                    <div className="d-flex flex-column">
                        <div className="logo-wrapper">
                            <img className="rdwn-logo footer-logo m-r-30" src="/assets/images/logos/rundownstudios_logo_horizontal 1_white_footer.png" alt="" />
                            <img className="rvx-logo footer-logo" src="/assets/images/revox/revox-logo-8.png" alt="" />
                            {/* <ProgressiveImage
                                preview="/assets/images/revox/revox-logo-8.png"
                                src="/assets/images/revox/revox-logo-8@2x.png"
                                render={(src, style) => <img src={src} />}
                            /> */}
                        </div>

                        <div className="m-t-40 col-md-8 text-white">
                            <div className="row">
                                <div className="col-md-6">
                                    <p className="overline revox-sub m-b-10 m-b-15">We place our heart and soul to the games we create</p>
                                </div>
                            </div>
                            <p className="text-trademark sm d-block m-b-0">Rundownstudios and certain product names used herein are trademarks or registered trademarks of Revox Private Limited and/or one of its subsidiaries or affiliates in The United States, Sri Lanka and/or other countries. See Trademarks for appropriate markings.</p>
                        </div>
                    </div>

                    {/* <div className="d-flex flex-column top-links">
                        <span className="text-white" style={{ whiteSpace: 'nowrap' }}>WHO WE ARE</span>
                        <span className="text-white display-block" style={{ marginTop: '52px' }}>PEOPLE</span>
                        <span className="text-white" style={{ marginTop: '52px' }}>WORK</span>
                        <span className="text-white" style={{ marginTop: '52px' }}>NEWS</span>
                    </div> */}

                </div>

                <div className="text-white" style={{ marginTop: '73px' }}>
                    <p className="sm ">Made alongside our colleagues at Revox Pvt Ltd</p>
                    <p className="copyright-tag sm">Copyright © 2022, Revox Private Limited and/or its subsidiaries or affiliates. All Rights Reserved.</p>
                </div>

                <div className="footer-legel-terms d-flex justify-content-between" style={{ marginTop: '64px' }}>
                    <div className="d-flex terms-section text-white">
                        <a target="_blank" href="https://www.revox.io/privacy" className="text-white m-r-20"><p className="overline m-r-20">Terms of use</p></a>
                        <a target="_blank" href="https://www.revox.io/privacy" className="text-white m-r-20"><p className="overline m-r-20">Cookie Policy</p></a>
                        <a target="_blank" href="mailto:hello@rundownstudios.io?subject = RundownStudios - Inquiry&body = " className="text-white m-r-20"><p className="overline m-r-20">Support</p></a>
                        <a target="_blank" href="https://www.revox.io/privacy" className="text-white m-r-20"><p className="overline m-r-20">Privacy Policy</p></a>
                    </div>

                    <div className="social-media-icons d-flex text-white fs-16 flex-wrap">
                        <a target="_blank" href="https://discord.gg/sgqDfxymgr" className="text-white m-r-20"><i className="bi bi-discord"></i></a>
                        <a target="_blank" href="https://www.youtube.com/channel/UCOC3wL7unSQl0MKUxnh7egg" className="text-white m-r-20"><i className="bi bi-youtube"></i></a>
                        <a target="_blank" href="https://www.facebook.com/RundownStudiosltd?mibextid=LQQJ4d" className="text-white m-r-20"><i className="bi bi-facebook"></i></a>
                        <a target="_blank" href="https://www.instagram.com/rundown_studios/" className="text-white m-r-20"><i className="bi bi-instagram"></i></a>
                        <a target="_blank" href="https://twitter.com/Rundown_Studio" className="text-white m-r-20"><i className="bi bi-twitter"></i></a>
                        <a target="_blank" href="https://www.twitch.tv/rundownstudios" className="text-white m-r-20"><i className="bi bi-twitch"></i></a>
                        <a target="_blank" href="https://www.threads.net/@rundown_studios" className="text-white m-r-20"><i className="bi bi-chat-square-heart-fill"></i></a>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Footer;