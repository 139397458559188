import './Game.css';
import React, { useState, useCallback } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { useNavigate } from 'react-router-dom';
function Game() {

  // const navigate = useNavigate();

  // function handleBackButton() {
  //   navigate('/');
  // }

  // window.addEventListener('popstate', handleBackButton);





  const { unityProvider, isLoaded, loadingProgression } = useUnityContext({
    loaderUrl: "/webgl/build/build.loader.js",
    dataUrl: "/webgl/build/build.data",
    frameworkUrl: "/webgl/build/build.framework.js",
    codeUrl: "/webgl/build/build.wasm",
  });

  const loadingPercentage = Math.round(loadingProgression * 100);

  // Use a state to store the device pixel ratio.
  const [devicePixelRatio, setDevicePixelRatio] = useState(
    window.devicePixelRatio
  );

  const handleChangePixelRatio = useCallback(
    function () {
      // A function which will update the device pixel ratio of the Unity
      // Application to match the device pixel ratio of the browser.
      const updateDevicePixelRatio = function () {
        setDevicePixelRatio(window.devicePixelRatio);
      };
      // A media matcher which watches for changes in the device pixel ratio.
      const mediaMatcher = window.matchMedia(
        `screen and (resolution: ${devicePixelRatio}dppx)`
      );
      // Adding an event listener to the media matcher which will update the
      // device pixel ratio of the Unity Application when the device pixel
      // ratio changes.
      mediaMatcher.addEventListener("change", updateDevicePixelRatio);
      return function () {
        // Removing the event listener when the component unmounts.
        mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
      };
    },
    [devicePixelRatio]
  );



  return (
    <div className="game-container">
      {isLoaded === false && (
        // Conditionally render the loading overlay if the Unity
        // Application is not loaded.
        <div className="loading-overlay">
          <p>Loading... ({loadingPercentage}%)</p>
        </div>
      )}
      <Unity className="unity" unityProvider={unityProvider} devicePixelRatio={devicePixelRatio} />
    </div>
  );
}

export default Game;