
import React from "react";
import { BrowserRouter, useRoutes } from "react-router-dom";

import Home from './components/home/Index';
import Game from './components/game/Game';
import BuildWithUs from './components/build-with-us/BuildWithUS';
import NavBar from "./components/navbar/NavBar";
import LandingPage from "./components/landing-page/LandingPage";
import Footer from "./components/footer/Footer";
import Blog from "./components/home/Blog";

import './App.scss';


const AppRoutes = () => {
  let routes = useRoutes([
    { path: "/", element: <LandingPage /> },
    { path: "/play", element: <Game /> },
    { path: "/build-with-us", element: <BuildWithUs /> },
    { path: "/home", element: <Home /> },
    { path: "/blog", element: <Blog /> }
  ]);
  return routes;
};

const App = () => {
  return (
    <BrowserRouter>
      <div id="pageContainer" className="page-container">
        <NavBar />
        <div className="content-wraper">
          <AppRoutes />
        </div>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;
