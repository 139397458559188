import React, { Suspense } from "react";

import Hero from "./Hero";

import Hero_IOS from "../IOS_Home/Hero_IOS";
import Project_IOS from "../IOS_Home/Project_IOS";
import Story_IOS from "../IOS_Home/Story_IOS";

// import Blog from "./Blog";
const Project = React.lazy(() => import("./Project"));
const Story = React.lazy(() => import("./Story"));
const Values = React.lazy(() => import("./Values"));
const BuildWithUs = React.lazy(() => import("./BuildWithUS"));
const People = React.lazy(() => import("./People"));
// import Project from "./Project";
// import Story from "./Story";
// import Values from "./Values";
// import BuildWithUs from "./BuildWithUS";
// import People from "./People";




function Index() {
    let isIOS = /iPad|iPhone|iPod/.test(navigator.platform)

    function changeOpacity() {
        let navBarElement = document.getElementById('navBar')
        if (document.documentElement.scrollTop > 100) {
            // let navBarElement = document.getElementById('navBar')
            navBarElement.classList.add('active');
        }
        else if (document.documentElement.scrollTop < 30) {
            // let navBarElement = document.getElementById('navBar')
            navBarElement.classList.remove('active');
        }
        // let navBarElement = document.getElementById('holloween-spider');
        // navBarElement.style.top = (document.documentElement.scrollTop) - 327;


    }
    window.addEventListener('scroll', changeOpacity);


    if (!isIOS) {
        return (

            <div className="home-page">
                <Hero />
                <Suspense fallback={<div>Loading...</div>}>
                    <Story />
                </Suspense>
                <Suspense fallback={<div>Loading...</div>}>
                    <Values />
                </Suspense>
                <Suspense fallback={<div>Loading...</div>}>
                    <Project />
                </Suspense>
                <Suspense fallback={<div>Loading...</div>}>
                    <People />
                </Suspense>
                <Suspense fallback={<div>Loading...</div>}>
                    <BuildWithUs />
                </Suspense>
            </div>
        );
    }
    else {
        return (

            <div className="home-page">
                <Hero_IOS />
                <Story_IOS />
                <Values />
                <Project_IOS />
                <People />
                {/* <Blog /> */}
                <BuildWithUs />
            </div>
        );
    }

}

export default Index;