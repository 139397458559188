import React from "react";

function BuildWithUs() {
    return (
        <div className="buldWithUS">
            
        </div>
    );
}

export default BuildWithUs;