import React, { useRef, useEffect } from 'react';

function Hero_IOS() {
    function scrollSpider() {
        if (document.documentElement.scrollTop < 800) {
            let navBarElement = document.getElementById('holloween-spider');
            navBarElement.style.top = (((document.documentElement.scrollTop) * 0.8) - 327) + "px";
        }
    }
    window.addEventListener('scroll', scrollSpider);


    return (

        <div className="hero" id="heroSection" >


            {/* <div className='holiday-deco halloween'>
                <img className='holloween-cobweb' src='/assets/images/Holloween/Fun/cobweb1_web.png'></img>
                <div className='holiday-deco inner'>
                    <img id="holloween-spider" className='holloween-cobweb spider' src='/assets/images/Holloween/Fun/cobweb1_spider.png'></img>
                </div>
            </div> */}

            {/* <div className='holiday-deco christmas'>
                <img className='holloween-cobweb' src='/assets/images/Christmas/Fun/starry_lights.png'></img>
            </div> */}

            <div className='holiday-deco avurudu'>
                <img className='bo_leaves' src='/assets/images/avurudu/bodhi_leaves.png'></img>
                <img className='swing' src='/assets/images/avurudu/swinging.png'></img>
                {/* <img className='christmas_branch' src='/assets/images/Christmas/fun/branch_with_baubles.png'></img> */}
            </div>

            <video src='/assets/videos/Website_Video.mp4' id="sectionVideo" className='section-video hero' webkit-playsinline={true} autoPlay muted playsInline loop></video>

            <div className="hero-container d-flex align-items-center justify-content-center ">
                <div className="d-block text-center hero-text-wrapper">
                    <p className="overline head m-b-12">Who we are</p>
                    <h1 className="m-b-12">We Engineer<br />reality.</h1>
                    <p className="text-opac-70">We believe in creating games you not <br />only play but one that you experience and remember.</p>
                </div>
                <div className="scroll-to-bottom float-bottom text-center">
                    <img src="/assets/images/mouse-icon.png" alt="" width={90} />

                    <p className="hint-text m-t-30">Scroll to see more</p>

                </div>
            </div>

        </div>
    );

}

export default Hero_IOS;