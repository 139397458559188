import React from "react";
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";

function Blog() {
    return (
        <div className="blog d-flex align-items-center justify-content-center" id="blog">
            <div className="container">
                <p className="hint-text m-b-10">Blog</p>
                <h2 className="text-opac-90 m-b-25">Our stories</h2>
                <div className="row blog-container d-flex flex-row m-t-50 justify-content-center">
                    <div className="col-md blog-card">
                        <img src="/assets/images/blog-1.png" alt="" className="blog-image"/>
                        <div className="blog-para">
                            <p className="overline text-opac-56 m-b-20">Community</p>
                            <p className="lead m-b-15">A map that has dynamic gravity and dynamic weather.</p>
                            <p className="sm text-opac-70 m-b-35">Design has the power to improve how people live their lives, with brave ideas fueled by bold, courageous thinking. </p>
                            <p className="sm">December 1, 2022 · 5 min read</p>
                        </div>
                    </div>
                    <div className="col-md blog-card">
                        <img src="/assets/images/blog-2.png" alt="" className="blog-image"/>
                        <div className="blog-para">
                            <p className="overline text-opac-56 m-b-20">Community</p>
                            <p className="lead m-b-15">Concept Art Drawing & Character Design</p>
                            <p className="sm text-opac-70 m-b-35">Custom characters are sketched out and then made into 3D using Meta-Human, before they are imported and animated in Unreal Engine 5.</p>
                            <p className="sm">December 1, 2022 · 5 min read</p>
                        </div>
                    </div>
                    <div className="col-md blog-card">
                        <img src="/assets/images/blog-3.png" alt="" className="blog-image"/>
                        <div className="blog-para">
                            <p className="overline text-opac-56 m-b-20">Community</p>
                            <p className="lead m-b-15">Team-Based Tactical FPS First-Person Shooter Multiplayer Game</p>
                            <p className="sm text-opac-70 m-b-35">Design has the power to improve how people live their lives, with brave ideas fueled by bold, courageous thinking. </p>
                            <p className="sm">December 1, 2022 · 5 min read</p>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center m-t-50">
                    <Link to={'/blog'}>
                        <Button variant="outline-primary" className="home-button">View All</Button>
                    </Link>
                </div>
                
            </div>
        </div>
    );
}

export default Blog;